<template>
  <div>
    <div class="full-width" id="split-yellow">
      <div class="inner flex"><VideoProfileFinal /> <MobileVideoSlider /></div>
    </div>
    <div class="full-width" id="numbers">
      <div class="inner" id="large-counter-section">
        <ul class="counter-section">
          <li id="stat1">
            <div id="coursesCount" class="numbers">
              <p>
                <span id="student-count">0</span>
                <!-- <br /><span
                  id="student-title"
                  class="second-line"
                  >students</span
                > -->
              </p>
            </div>
            <p class="under-stat">
              approximate undergraduate
              <!-- approx. undergraduate -->
              <!-- <br class="enrollment-break" /> -->
              <br />student enrollment
            </p>
            <span class="bottom-border"></span>
          </li>
          <li id="stat2">
            <div id="coursesCount" class="numbers">
              <p>
                <span id="on-campus-count">3</span
                ><span class="percent">:1</span
                ><span class="second-line"></span>
              </p>
            </div>
            <p id="on-campus-title" class="under-stat">
              student-to-faculty <br />ratio
            </p>
            <span class="bottom-border"></span>
          </li>
          <li id="stat3">
            <div id="coursesCount" class="numbers">
              <p>
                <span id="return-count">45</span><span class="percent">%</span>
              </p>
            </div>
            <p id="return-title" class="under-stat">
              of students return <br />sophomore year
            </p>
            <span class="bottom-border"></span>
          </li>
          <li id="stat4">
            <div id="coursesCount" class="numbers">
              <p><span id="recruit-count">100</span><span>+</span></p>
            </div>
            <p id="recruit-title" class="under-stat">
              student <br />organizations
            </p>
            <span class="bottom-border"></span>
          </li>
        </ul>
        <ul class="counter-section second-counter">
          <li id="stat5">
            <div id="coursesCount" class="numbers">
              <p><span id="student-count">3</span><span>th highest</span></p>
              <span class="bottom-border"></span>
            </div>
            <p class="under-stat">
              4-year graduation rate among the nation’s traditional,
              <br />four-year public universities<em> — IPEDS, 2022</em>
            </p>
            <span class="bottom-border lower-border"></span>
          </li>
        </ul>
        <!-- <p id="five-year-average">*5-year average</p> -->
      </div>
    </div>
    <div class="full-width yellow-background">
      <AcademicPathwaysFinal />
    </div>
  </div>
</template>

<script>
import AcademicPathwaysFinal from "./components/AcademicPathwaysFinal.vue";
import VideoProfileFinal from "./components/VideoProfileFinal.vue";
import MobileVideoSlider from "./components/MobileVideoSlider.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "App",
  components: {
    AcademicPathwaysFinal,

    MobileVideoSlider,

    VideoProfileFinal,
  },
  mounted: function () {
    gsap.registerPlugin(ScrollTrigger);
    // this.sliderImages();
    // this.removeAutoPlayVideoMobile();
    //Hide for slideshow
    // this.removeAutoPlayHeroVideoMobile();
    //End hide for slideshow
    this.headerCarousel();
    // Hide for video new header carousel for video hero
    this.newHeaderCarousel();
    // End hide for video
    this.scrollAnimation();
    this.pathwaysFadeIn();
    this.featuredStoryAnimation();
  },
  methods: {
    removeAutoPlayHeroVideoMobile() {
      const videoLocation = document.querySelectorAll("#commencement-video");
      // const videoHero = document.querySelector("#commencement-video");
      const heroPlayPauseButton = document.querySelector("#play-pause");
      heroPlayPauseButton.addEventListener("click", () => {
        // document.querySelector("#commencement-video").play();
        videoLocation.forEach((vid) => {
          const isPaused = vid.paused;
          // vid.pause()
          vid[isPaused ? "play" : "pause"]();
          heroPlayPauseButton.classList.toggle("video-paused", !isPaused);
        });

        // heroPlayPauseButton.textContent = `Click count: ${event.detail}`;
      });

      //For desktop video include below
      const videoSourceDesktop =
        // "https://player.vimeo.com/progressive_redirect/playback/703346369/rendition/720p?loc=external&signature=c3d4ca0d59e3d09ea716e8ffd094b16847899a8ddf714502628a37d117ffe5e6";
        // "/home-page-loop-update.mov";
        // 1080 p
        "https://player.vimeo.com/progressive_redirect/playback/708756410/rendition/720p/file.mp4?loc=external&signature=b0cc0bd5279ef5a8c929765fc6cc8f44b0fe97984e54f30e39f9c318c18d46e9";
      //For Mobile video include below
      const videoSources = [
        // "https://player.vimeo.com/progressive_redirect/playback/703346401/rendition/720p?loc=external&signature=5e6138b95b0851bfbce13a57040f0d3a977dc2f8cd3613dfc01a011f693e518a",
        "https://player.vimeo.com/progressive_redirect/playback/704681732/rendition/720p?loc=external&signature=50eb53cb3155ea731996578e38eece7a379d1ddbdc21113380d2eca014f4116e",
      ];
      const videoPosterDesktop =
        "https://tcnj.edu/custom/commencement/commencement-desktop-still.jpg";
      const videoPoster = [
        "https://tcnj.edu/custom/commencement/homepage-mobile-hero.jpg",
      ];
      // const pauseButton = document.querySelector("#pause");
      // document.addEventListener("DOMContentLoaded", function(){
      //     document.body.addEventListener("touchstart", playVideo);
      //     function playVideo() {
      //         if(videoLocation.playing) {
      //         } else {
      //             videoLocation.play();
      //         }
      //     }
      // });

      // var sources = document.querySelectorAll("#videoBG source");

      // Define the video object this source is contained inside
      // var video = document.querySelector('video#my-id');

      // If for some reason we do want to load the video after, for desktop as opposed to mobile (I'd imagine), use videojs API to load
      try {
        // videoLocation.load();
        // If for some reason we do want to load the video after, for desktop as opposed to mobile (I'd imagine), use videojs API to load
        // videoLocation.load();

        const tabletMode = window.matchMedia("(max-width: 1024px)");
        const removeAutoPlay = (tabletMode) => {
          if (tabletMode.matches) {
            videoLocation.forEach((video, index) => {
              // If media query matches
              // pauseButton.style.display = 'none';
              video.setAttribute("autoplay", "false");
              video.setAttribute("src", videoSources[index]);
              video.setAttribute("poster", videoPoster[index]);
              video.setAttribute("preload", "none");
              video.setAttribute("onloadeddata", "this.pause();");
              video.pause();
              const heroPlayPauseButton = document.querySelector("#play-pause");
              heroPlayPauseButton.classList.toggle("video-paused");

              // heroPlayPauseButton.classList.toggle("video-paused");
            });
          } else {
            videoLocation.forEach((video) => {
              // pauseButton.style.display = 'flex';
              // video.setAttribute("autoplay", "true");
              // video.setAttribute("preload", "auto");
              video.setAttribute("src", videoSourceDesktop);
              video.setAttribute("poster", videoPosterDesktop);

              // video.setAttribute("onloadeddata", "this.play();");
              // video.play();
            });
            // videoLocation[0].setAttribute("autoplay", "true");
            // videoLocation[0].setAttribute("preload", "auto");
            // videoLocation[0].setAttribute("onloadeddata", "this.play();");
            // videoLocation[0].play();
          }
        };

        removeAutoPlay(tabletMode); // Call listener function at run time
        tabletMode.addListener(removeAutoPlay); // Attach listener function on state changes
      } catch (err) {
        console.log(err);
      }
    },
    removeAutoPlayVideoMobile() {
      const videoLocation = document.querySelectorAll(".inner-container video");
      const videoSources = [
        "https://player.vimeo.com/progressive_redirect/playback/686867392/rendition/540p?loc=external&signature=7364cc804502e3bee073f0feb9fa89539a458487e4e0561fefef6668535f6007",

        "https://player.vimeo.com/progressive_redirect/playback/686867536/rendition/720p?loc=external&signature=8c0e5065168a8fac89ef0da5c3561921d834e299c40e1e2a7db8026e402b4054",

        "https://player.vimeo.com/progressive_redirect/playback/686867360/rendition/720p?loc=external&signature=fe072dbb2224e605565af402056109f4e832e90cd6daab636de991fc08d8139f",
      ];
      // const pauseButton = document.querySelector("#pause");
      // document.addEventListener("DOMContentLoaded", function(){
      //     document.body.addEventListener("touchstart", playVideo);
      //     function playVideo() {
      //         if(videoLocation.playing) {
      //         } else {
      //             videoLocation.play();
      //         }
      //     }
      // });

      // var sources = document.querySelectorAll("#videoBG source");

      // Define the video object this source is contained inside
      // var video = document.querySelector('video#my-id');

      // If for some reason we do want to load the video after, for desktop as opposed to mobile (I'd imagine), use videojs API to load
      try {
        // videoLocation.load();
        // If for some reason we do want to load the video after, for desktop as opposed to mobile (I'd imagine), use videojs API to load
        // videoLocation.load();

        const tabletMode = window.matchMedia("(max-width: 1024px)");
        const removeAutoPlay = (tabletMode) => {
          if (tabletMode.matches) {
            videoLocation.forEach((video) => {
              // If media query matches
              // pauseButton.style.display = 'none';
              video.setAttribute("autoplay", "false");
              video.setAttribute("src", "");
              video.setAttribute("preload", "none");
              video.setAttribute("onloadeddata", "this.pause();");
              video.pause();
            });
          } else {
            videoLocation.forEach((video, index) => {
              // pauseButton.style.display = 'flex';
              // video.setAttribute("autoplay", "true");
              // video.setAttribute("preload", "auto");
              video.setAttribute("src", videoSources[index]);

              // video.setAttribute("onloadeddata", "this.play();");
              // video.play();
            });
            // videoLocation[0].setAttribute("autoplay", "true");
            // videoLocation[0].setAttribute("preload", "auto");
            // videoLocation[0].setAttribute("onloadeddata", "this.play();");
            // videoLocation[0].play();
          }
        };

        removeAutoPlay(tabletMode); // Call listener function at run time
        tabletMode.addListener(removeAutoPlay); // Attach listener function on state changes
      } catch (err) {
        console.log(err);
      }
    },

    newHeaderCarousel() {
      var current = 0,
        slides = document.querySelectorAll("#slider-images img");

      setInterval(function () {
        for (var i = 0; i < slides.length; i++) {
          slides[i].style.opacity = 0;
        }
        current = current != slides.length - 1 ? current + 1 : 0;
        slides[current].style.opacity = 1;
      }, 8000);
    },

    sliderImages() {
      var duration = 0.5;
      var delay = 5;
      var imageIndex = document.querySelectorAll("#slider-images img");
      var tl = gsap.timeline({ onComplete: repeat });

      gsap.utils.toArray(imageIndex).forEach((image, index) => {
        var offset = index === 0 ? 0 : "-=" + duration; //insert first animation at a time of 0 or all other animations at a time that will overlap with the previous animation fading out.
        tl.to(
          image,
          { duration, autoAlpha: 1, repeat: 1, yoyo: true, repeatDelay: delay },
          offset
        );
        //when the last image fades out we need to cross-fade the first image
        if (index === imageIndex.length - 1) {
          tl.to(imageIndex[0], { duration, autoAlpha: 1 }, offset);
        }
      });

      function repeat() {
        tl.play(duration);
      }
    },
    headerCarousel() {
      gsap.fromTo(
        ".fade-on-load",
        { opacity: 0 },
        {
          autoAlpha: 1,
          delay: 0.5,

          // stagger: 0.4,
        }
      );

      let bannerTextContainer = document.querySelectorAll(".banner-text");
      const headerTextCarousel = gsap.timeline();

      bannerTextContainer.forEach((textContainer) => {
        headerTextCarousel
          .fromTo(
            textContainer,
            { opacity: 0, x: 30, y: 0 },
            {
              delay: 0.5,
              autoAlpha: 1,
              x: 0,
              y: 0,
              // duration: 0.6,
              // stagger: 0.4,
            }
          )

          .to(textContainer, { duration: 8 })
          // takeover change
          // .to(textContainer, { duration: 500 })
          .to(textContainer, { opacity: 0, x: 30, y: 0 });
      });

      headerTextCarousel.repeat(-1);
      let reportText = document.querySelectorAll(".inner-report p");
      const reportTextCarousel = gsap.timeline();

      reportText.forEach((textContainer) => {
        reportTextCarousel
          .fromTo(
            textContainer,
            { opacity: 0, x: 30, y: 0 },
            {
              delay: 0.5,
              autoAlpha: 1,
              x: 0,
              y: 0,
              // duration: 0.6,
              // stagger: 0.4,
            }
          )

          .to(textContainer, { duration: 8 })
          .to(textContainer, { opacity: 0, x: 30, y: 0 });
      });

      reportTextCarousel.repeat(-1);
    },

    scrollAnimation() {
      gsap.registerEffect({
        name: "counter",
        extendTimeline: true,
        defaults: {
          end: 0,
          duration: 0.5,
          ease: "power1",
          increment: 1,
        },
        effect: (targets, config) => {
          const tl = gsap.timeline();
          const num = targets[0].innerText.replace(/,/g, "");
          targets[0].innerText = num;

          tl.to(
            targets,
            {
              duration: config.duration,
              innerText: config.end,
              // snap:{innerText:config.increment},
              modifiers: {
                innerText(innerText) {
                  return gsap.utils
                    .snap(config.increment, innerText)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
              ease: config.ease,
            },
            0
          );

          return tl;
        },
      });

      const counterTrigger = document.querySelectorAll(".counter-section");

      let statTicker = gsap.timeline();
      const numberArray = [7000, 13, 90, 200, 9];
      const endInArray = [10, 1, 5, 5, 1];
      let boxes = document.querySelectorAll(".counter-section li");
      boxes.forEach((box, index) => {
        let overlay = box.querySelector("span");
        // let para = box.querySelector(".inner p");
        // fadeIn;
        statTicker
          // .set(boxes, { autoAlpha: 0, y: -30 })
          .fromTo(
            box,
            { opacity: 0, y: 30, paused: true },
            { autoAlpha: 1, y: 0, paused: false }
          )
          // .from(box, { autoAlpha: 0, paused: true })
          .counter(
            overlay,
            {
              end: numberArray[index],
              increment: endInArray[index],
              duration: 0.3,
            },
            "-=0.5"
          );
        // .to(para, { duration: 1, color: "black" }, index + 1);
      });
      ScrollTrigger.create({
        start: "top 90%",
        end: "top 40%",

        animation: statTicker,
        trigger: counterTrigger,

        toggleActions: "restart none none none",
      });
    },
    pathwaysFadeIn() {
      const schoolTitle = gsap.from(
        "#school-level p",
        { x: 30, opacity: 0, duration: 0.3, stagger: 0.15, paused: true },
        2
      );

      ScrollTrigger.create({
        trigger: "#school-level p",
        start: "top 60%",
        onEnter: () => schoolTitle.play(),
      });

      ScrollTrigger.create({
        trigger: "#school-level p",
        start: "top bottom",
        onLeaveBack: () => schoolTitle.pause(0),
      });
    },
    featuredStoryAnimation() {
      // desktop

      const fadeBottomUp = gsap.from(
        ".gsap-fade.bottom-up",
        { y: 15, delay: 0.2, duration: 0.5, stagger: 0.4 },

        0.5
      );

      ScrollTrigger.create({
        trigger: "#news-feed",
        start: "top 70%",
        // markers: true,
        toggleActions: "play none none none",
        animation: fadeBottomUp,
        // onEnter: () => fadeBottomUp.play(),
      });

      // const spotlightTl = gsap.timeline({ paused: true });
      // const spotLights = document.querySelectorAll("#spotlight .flex-2");

      // spotLights.forEach((spotlight) => {
      //   spotlightTl.fromTo(
      //     spotlight,
      //     { x: 0, y: 30 },
      //     {
      //       delay: 0.2,
      //       autoAlpha: 1,
      //       x: 0,
      //       y: 0,
      //     },
      //     "-=0.4"
      //   );
      // });

      // ScrollTrigger.create({
      //   trigger: "#spotlight",
      //   start: "center 70%",
      //   // markers: true,
      //   toggleActions: "play none none none",
      //   animation: spotlightTl,
      //   // onEnter: () => fadeBottomUp.play(),
      // });
    },
  },
};
</script>
